<template>
    <div>
        <MarcaDev />
        <Loader v-if="carregando" :progress="progress" />
        <div v-show="autenticado && !carregando">
            <section class="section">
                <div class="container"> 
                    <div class="barra-superior">
                        <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                            <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 150px; margin-left: 1.5rem" />
                            <span style="font-size: 24px; margin: auto">Indicadores</span>
                        </div>
                        <div style="margin-right: 2rem; display: flex; gap: 1rem">
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Digitação`" 
                                :onclick="indicador"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Pesquisa`" 
                                :onclick="pesquisa"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Complemento`" 
                                :onclick="complemento"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined is-active`" 
                                :titulo="`Exportação`" 
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Documentos`" 
                                :onclick="documentos"
                                v-if="autenticacao.permissoes?.acessarDocumentos?.includes(autenticacao.permissao)"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Endereços`" 
                                :onclick="enderecos"
                                v-if="autenticacao.permissoes?.acessarEnderecos?.includes(autenticacao.permissao)"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Estatísticas`" 
                                :onclick="estatisticas"
                                v-if="autenticacao.permissoes?.acessarEstatisticas?.includes(autenticacao.permissao)"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <div class="dropdown is-right is-hoverable">
                                <div class="dropdown-trigger">
                                    <button class="button is-secondary" aria-haspopup="true" aria-controls="dropdown-menu3">
                                        <i class="gg-more-vertical-alt"></i>
                                    </button>
                                </div>
                                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                    <div class="dropdown-content">
                                        <a class="dropdown-item" @click="usuariosPagina()" v-if="autenticacao.permissoes?.acessarUsuarios?.includes(autenticacao.permissao)">
                                            Usuários
                                        </a>
                                        <a class="dropdown-item" @click="sair()">
                                            Sair
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="formulario">                 
                        <div class="columns" v-if="autenticacao.permissoes?.gerarNovaExportacao?.includes(autenticacao.permissao)">
                            <div class="column is-full">
                                <b style="font-size: 22px">Gerar nova exportação</b>
                            </div>
                            <div class="column is-4 column-date">
                                <InputText
                                    :label="`Data inicial de cadastro`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="exportacao.dataInicialCadastroExportacao"
                                />
                                <span>a</span>
                                <InputText
                                    :label="`Data final de cadastro`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="exportacao.dataFinalCadastroExportacao"
                                />
                            </div>
                            <div class="column is-4 column-date">
                                <InputText
                                    :label="`Data inicial de atualização`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="exportacao.dataInicial"
                                />
                                <span>a</span>
                                <InputText
                                    :label="`Data final de atualização`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="exportacao.dataFinal"
                                />
                            </div>
                            
                            <div class="column is-4" style="gap: 1rem; display: flex;">
                                <Button
                                    :classes="`is-success is-outlined`" 
                                    :icone="`search`" 
                                    :titulo="`Exportar`" 
                                    :onclick="exportarOnr" 
                                    style="width: 15rem"
                                />
                            </div>
                        </div>

                        <hr style="margin-top: -0.5rem;" v-if="autenticacao.permissoes?.gerarNovaExportacao?.includes(autenticacao.permissao)">          

                        <div class="columns">
                            <div class="column is-full">
                                <b style="font-size: 22px">Filtros da exportação</b>
                            </div>
                            <div class="column is-4 column-date">
                                <InputText
                                    :label="`Data inicial da solicitação`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataInicialCadastro"
                                />
                                <span>a</span>
                                <InputText
                                    :label="`Data final da solicitação`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataFinalCadastro"
                                />
                            </div>

                            <div class="column is-4 column-date">
                                <InputText
                                    :label="`Data inicial da geração`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataInicialGeracao"
                                />
                                <span>a</span>
                                <InputText
                                    :label="`Data final da geração`" 
                                    :tipo="`date`" 
                                    :preenchido="true"
                                    v-model="form.dataFinalGeracao"
                                />
                            </div>

                            <div class="column is-2">
                                <InputText
                                    :label="`Hash`" 
                                    :preenchido="true"
                                    v-model="form.hash"
                                />
                            </div>      
                            
                            <div class="column is-2">
                                <Select 
                                    :label="`Situação`" 
                                    :preenchido="true"
                                    v-model="form.situacao"
                                    ref="select"
                                >
                                    <option value="">Todas</option>
                                    <option value="0">Solicitada</option>
                                    <option value="1">Em andamento</option>
                                    <option value="2">Erro na geração</option>
                                    <option value="3">Finalizada</option>
                                    <option value="4">Expirada</option>
                                    <option value="5">Erro na validação dos dados</option>
                                </Select>
                            </div>
                            
                            <div class="column is-12" style="gap: 1rem; display: flex; justify-content: flex-end;">
                                <Button
                                    :classes="`is-danger is-outlined btn-limpar`" 
                                    :icone="`trash`" 
                                    :titulo="`Limpar filtro`" 
                                    :onclick="limparFiltro" 
                                    :parametro1="1"
                                    v-show="pesquisado"
                                    style="width: 15rem"
                                />

                                <Button
                                    :classes="`is-link is-outlined btn-pesquisar`" 
                                    :icone="`search`" 
                                    :titulo="`Pesquisar`" 
                                    :onclick="pesquisar" 
                                    :parametro1="true" 
                                    :parametro2="true" 
                                    style="width: 15rem"
                                />
                            </div>
                        </div>

                        <hr style="margin-top: -0.5rem;">

                        <Loader 
                            v-if="carregandoBusca" 
                            :altura="20"
                            style="margin-bottom: 2rem;"
                        />
                
                        <div class="columns column-table column-nao-encontrado" v-show="totalRegistros == 0">
                            <div>Nenhum registro encontrado</div>
                        </div>
                        
                        <Pagination 
                            :contadorPaginas.sync="contadorPaginas" 
                            :totalRegistros.sync="totalRegistros" 
                            :paginaAtual.sync="paginaAtual" 
                            :paginas.sync="paginas" 
                            :totalPaginas.sync="totalPaginas"
                                :numeroExibicaoRegistros.sync="numeroExibicaoRegistros"
                            v-show="dados != false"
                        />
                        
                        <div class="columns column-table" v-show="dados != false">
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th style="min-width: 105px;">Hash</th>
                                            <th style="min-width: 105px;">Tipo</th>
                                        <th style="min-width: 105px;">Total de registros<br>exportados</th>
                                        <th style="min-width: 135px;">Data da solicitação</th>
                                        <th style="min-width: 100px;">Data da geração</th>
                                        <th style="min-width: 100px;">Situação</th>
                                        <th style="min-width: 100px; width: 12%; text-align: center;">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dado, idx) in dados">
                                        <td style="vertical-align: middle;">{{ dado.hash }}</td>
                                            <td style="vertical-align: middle;">{{ tiposExportacao[dado.tipo] ?? 'Dados ONR' }}</td>
                                        <td style="vertical-align: middle;">{{ dado.totalRegistrosExportados }}</td>
                                        <td style="vertical-align: middle;">{{ dado.dataCadastro }}</td>
                                        <td style="vertical-align: middle;">{{ dado.dataGeracao }}</td>
                                        <td style="vertical-align: middle;">{{ dado.situacao }}</td>
                                        <td>
                                            <Button
                                                :classes="`is-purple btn-pesquisar btn-csv`" 
                                                :icone="``" 
                                                :titulo="`Baixar`" 
                                                :onclick="exportar" 
                                                :parametro1="dado" 
                                                v-if="dado.totalRegistrosExportados != 0 || dado.tipo == 'doi'"
                                                v-show="!dado.erros || dado.erros.length == 0"                                                    
                                                :style="`width: 100%; margin: 0; visibility: ${dado.situacao == 'Finalizada' ? 'visibility;' : 'hidden;'}`"
                                            />
                                            <Button
                                                :classes="`is-warning btn-pesquisar btn-csv`" 
                                                :icone="``" 
                                                :titulo="`Visualizar erros`" 
                                                :onclick="visualizarErros" 
                                                :parametro1="dado.erros" 
                                                v-if="dado.totalRegistrosExportados != 0"
                                                v-show="dado.erros && dado.erros.length > 0"
                                                :style="`width: 100%; margin: 0;`"
                                            />
                                            <span v-else v-show="dado.tipo != 'doi'">Nenhum registro exportado</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr style="margin-top: -0.5rem; margin-bottom: 0.5rem;">

                        <Pagination 
                            :contadorPaginas.sync="contadorPaginas" 
                            :totalRegistros.sync="totalRegistros" 
                            :paginaAtual.sync="paginaAtual" 
                            :paginas.sync="paginas" 
                            :totalPaginas.sync="totalPaginas"
                                :numeroExibicaoRegistros.sync="numeroExibicaoRegistros"
                            v-show="dados != false"
                        />
                    </div>
    
                    <div class="formulario" style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 3rem;
                    ">
                        <Versao :horaAtual="horaAtual" />
                        
                        <Button
                            :classes="`is-${contagemRegressivaClass} btn-small`" 
                            style="margin-right: -45px; width: 16rem;"
                            :onclick="refreshToken"
                            :titulo="`${contagemRegressiva}`" 
                        />
                    </div>
                </div>
            </section>
        </div>
    
        <div class="modal" id="modal-confimacao" :class="{ 'is-active': modalLimpeza }">
            <div class="modal-background" @click="modalLimpeza = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Limpar o filtro</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="modalLimpeza = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="content">
                        Deseja realmente limpar o filtro?
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: space-between;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="modalLimpeza = false">Não</button>
                    <button class="button is-danger is-outlined" @click="limparFiltro(0)">Sim</button>
                </footer>
            </div>
        </div>

        <div class="modal" id="modal-confimacao" :class="{ 'is-active': dadosErros != false }">
            <div class="modal-background" @click="dadosErros = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Erros da exportação</p>
                    <button class="delete" aria-label="close" tabindex="-1" @click="dadosErros = false"></button>
                </header>
                <section class="modal-card-body" style="display: block;">
                    <div class="columns column-table" v-if="dadosErros != null">                                    
                        <table class="table is-fullwidth is-hoverable">
                            <thead>
                                <tr>
                                    <th>Matrícula</th>
                                    <th>Descrição do erro</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(erros, idx) in dadosErros">
                                    <td>{{ erros.matricula }}</td>
                                    <td>{{ erros.descricao }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="text-align: center" v-else>    
                        Nenhum histórico registrado!
                    </div>
                </section>
                <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
                    <button class="button is-link is-outlined" tabindex="-1" @click="dadosErros = false">Fechar</button>
                </footer>
            </div>
        </div> 
    </div>
</template>

<script>
    import axios from "axios"
    import qs from "qs"
    import moment from "moment"
    import { autenticar } from '../../services/auth.js'

    export default {
        name: "Exportacao",
        components: {},
        data(){
            return {
                modalLimpeza: false,
                pesquisado: false,
                carregando: false,
                carregandoBusca: false,
                autenticado: true,
                token: {},
                form: {
                    dataInicialCadastro: '',
                    dataFinalCadastro: '',
                    dataInicialGeracao: '',
                    dataFinalGeracao: '',
                    tipo: ['csv', 'atos'],
                    hash: '',
                    situacao: ''
                },
                tipo: '',
                exportacao: {
                    dataInicialCadastroExportacao: '',
                    dataFinalCadastroExportacao: '',
                    dataInicial: '',
                    dataFinal: ''
                },
                autenticacao: {
                    autenticacao: false
                },
                dados: false,
                tiposExportacao: {
                    'csv': 'Listagem em CSV',
                    'onr': 'Dados ONR',
                    'doi': 'DOI',
                    'atos': 'Relatório de atos',
                    'registros': 'Registros em JSON'
                },
                paginaAtual: 1, 
                totalRegistros: 1,
                totalPaginas: 1,
                contadorPaginas: 100,
                numeroExibicaoRegistros: 100,
                expiracaoInterval: null,
                horaAtual: moment().format('HH:mm'),
                contagemRegressiva: '00:00:00',
                contagemRegressivaClass: 'default',
                progress: 0,
                dadosErros: false,
                erros: []
            }
        },
        async created(){
            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            if(!this.autenticacao.permissoes?.acessarExportacao?.includes(this.autenticacao.permissao)){
                this.autenticado = false

                toastAlert('Você não está autorizado, redirecionando para a autenticação...')

                setTimeout(() => {
                    /**
                     * Desloga da aplicação
                     */
                    sessionStorage.removeItem('access_token')

                    window.location.href = '/'
                }, 2000)
            }

            /**
             * Atualiza os tipos de exportação do filtro
             */
            if(this.autenticacao.permissoes?.gerarExportacaoDoi?.includes(this.autenticacao.permissao)){
                this.form.tipo.push('doi')                
            }

            if(this.autenticacao.permissoes?.gerarExportacaoOnr?.includes(this.autenticacao.permissao)){             
                this.form.tipo.push('onr')                
            }

            if(this.autenticacao.permissoes?.exportarRegistros?.includes(this.autenticacao.permissao)){             
                this.form.tipo.push('registros')                
            }

            /**
             * Realizar a pesquisa automatica
             */
            this.pesquisar(false)
        },
        async mounted() {
            /**
             * Realizar a autenticação
             */
            await autenticar(this)

            /**
             * Realiza a contagem da expiração
             */
            this.calcularExpiracao()
            this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)

            /**
             * Eventos para capturar o comando CTRL + Q
             */
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                    const oInputs = document.querySelectorAll('input')

                    oInputs.forEach(input => {
                        input.blur()
                    })

                    this.pesquisar()
                }
            });
        },
        watch: {
            paginaAtual: {
                handler: function (novoValor, valorAntigo) {
                    if(novoValor != valorAntigo){
                        this.pesquisar()
                    }
                }
            }
        },
        computed: {
            paginas() {
                let intervalo = 2

                intervalo = this.paginaAtual == 1 ? 4 : intervalo
                intervalo = this.paginaAtual == 2 ? 3 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas ? 4 : intervalo
                intervalo = this.paginaAtual == this.totalPaginas - 1 ? 3 : intervalo
                
                const primeiraPagina = Math.max(1,  this.paginaAtual - intervalo);
                const ultimaPagina = Math.min(this.totalPaginas,  this.paginaAtual + intervalo);
                
                return Array.from({ length: ultimaPagina - primeiraPagina + 1 }, (_, index) => primeiraPagina + index);
            }
        },
        methods: {
            /**
             * Função para limpar o filtro
             */
            limparFiltro(iTipo){
                if(iTipo == 1){
                    this.modalLimpeza = true
                }else{
                    this.modalLimpeza = false
                    this.pesquisado = false
                    this.totalRegistros = 1
                    this.dados = false
                    this.form = {
                        dataInicialCadastro: '',
                        dataFinalCadastro: '',
                        dataInicialGeracao: '',
                        dataFinalGeracao: '',
                        tipo: this.form.tipo,
                        hash: '',
                        situacao: ''
                    }

                    /**
                     * Define o select pela referencia
                     *
                     * @var {object} oSelect
                     */
                     const oSelect = this.$refs.select;
                    oSelect.clearSelectize()
                    
                    this.pesquisar()
                }
            },
            /**
             * Função para pesquisar
             */
            async pesquisar(bFiltro = true, bResetarPagina = false){
                if(this.form.dataInicial != '' && this.form.dataFinal != '' && moment(this.form.dataInicial).isAfter(moment(this.form.dataFinal))){
                    toastAlert('A data inicial não pode ser maior que a data final')

                    return false
                }

                if(this.form.dataInicialCadastroExportacao != '' && this.form.dataFinalCadastroExportacao != '' && moment(this.form.dataInicialCadastroExportacao).isAfter(moment(this.form.dataFinalCadastroExportacao))){
                    toastAlert('A data inicial não pode ser maior que a data final')

                    return false
                }

                this.carregado = true
                this.carregandoBusca = true
                this.dados = false

                /**
                 * Define o contador
                 * 
                 * @var {int} iContador
                 */
                let iContador = 0

                if(sessionStorage.getItem('parametrosDocumentos') != null && !bFiltro){
                    /**
                     * Pega os parametros do storage
                     * 
                     * @var {object} oParametros
                     */
                    const oParametros = JSON.parse(sessionStorage.getItem('parametrosDocumentos'))
                    
                    this.form.dataInicialCadastro = oParametros.dataInicialCadastro
                    this.form.dataFinalCadastro = oParametros.dataFinalCadastro
                    this.form.dataInicialGeracao = oParametros.dataInicialGeracao
                    this.form.dataFinalGeracao = oParametros.dataFinalGeracao
                    this.form.tipo = oParametros.tipo
                    this.form.hash = oParametros.hash
                    this.tipo = oParametros.tipo
                    this.form.situacao = oParametros.situacao
                    this.paginaAtual = oParametros.pagina
                    this.limiteRegistros = oParametros.numeroExibicaoRegistros
                }

                if(bResetarPagina){
                    this.paginaAtual = 1
                }
                
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicialCadastro: this.form.dataInicialCadastro,
                    dataFinalCadastro: this.form.dataFinalCadastro,
                    dataInicialGeracao: this.form.dataInicialGeracao,
                    dataFinalGeracao: this.form.dataFinalGeracao,
                    tipo: this.form.tipo,
                    hash: this.form.hash,
                    situacao: this.form.situacao,
                    pagina: this.paginaAtual,
                    limiteRegistros: this.numeroExibicaoRegistros
                })

                sessionStorage.setItem('parametrosExportacao', JSON.stringify({
                    dataInicialCadastro: this.form.dataInicialCadastro,
                    dataFinalCadastro: this.form.dataFinalCadastro,
                    dataInicialGeracao: this.form.dataInicialGeracao,
                    dataFinalGeracao: this.form.dataFinalGeracao,
                    tipo: this.form.tipo,
                    hash: this.form.hash,
                    situacao: this.form.situacao,
                    pagina: this.paginaAtual,
                    limiteRegistros: this.numeroExibicaoRegistros
                }))

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)
              
                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/exportacao/onr?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(this.form.dataInicialCadastro == '' && this.form.dataFinalCadastro == '' && this.form.dataInicialGeracao == '' && this.form.dataFinalGeracao == '' && this.form.hash == '' && (!this.form.situacao || this.form.situacao == '')){
                        this.pesquisado = false
                    }else{
                        this.pesquisado = true
                    }

                    this.totalPaginas = Math.ceil(response.data.totalRegistros / this.numeroExibicaoRegistros)
                    this.totalRegistros = response.data.totalRegistros
                    this.contadorPaginas = this.numeroExibicaoRegistros * this.paginaAtual

                    if(this.contadorPaginas > response.data.totalRegistros){
                        this.contadorPaginas = response.data.totalRegistros
                    }
                    
                    response.data.data.map((oDado) => {
                        oDado.dataCadastro = moment(oDado.dataCadastro).format('DD/MM/YYYY HH:mm')

                        if(oDado.dataGeracao){
                            oDado.dataGeracao = moment(oDado.dataGeracao).format('DD/MM/YYYY HH:mm')
                        }
                       
                       if((oDado.situacao == 0 || oDado.situacao == 1) && iContador == 0){
                           setTimeout(() => {
                               this.pesquisar()
                           }, 5000)

                           iContador++
                       }

                        /**
                         * Define as situações
                         *
                         * @var {object} oSituacoes
                         */
                        const oSituacoes = {
                            0: 'Solicitada',
                            1: 'Em andamento',
                            2: 'Erro na geração',
                            3: 'Finalizada',
                            4: 'Expirada',
                            5: 'Erro na validação dos dados'
                        }

                        oDado.situacao = oSituacoes[oDado.situacao]
                    })

                    this.dados = response.data.data
                })
                .catch((error) => {
                    this.totalRegistros = 0
                    console.log(error)
                    toastAlert(`Erro ao buscar dados${error.response.data.descricao ? `: ${error.response.data.descricao}` : ''}`)
                });

                this.carregandoBusca = false
            },
            /**
             * Função para buscar usuarios
             */
            async buscarUsuarios(){
                await axios.request({
                    method: 'get',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/usuarios`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                })
                .then((response) => {
                    if(response.data.usuarios){
                        response.data.usuarios = response.data.usuarios.reduce((acc, valor, index) => {
                            acc[valor] = valor;
                            return acc;
                        }, {});

                        this.usuarios = response.data.usuarios
                        
                        /**
                         * Define o select pela referencia
                         *
                         * @var {object} oSelect
                         */
                        const oSelect = this.$refs.select;
                        oSelect.updateOptions(response.data.usuarios)
                        
                        oSelect.clearSelectize()
                    }

                })
                .catch((error) => {
                    console.log(error)
                    toastAlert(`Erro ao buscar os usuários${error?.response?.data?.descricao ? `: ${error.response.data.descricao}` : ''}`)
                });

                this.pesquisar(false)
            },
            /**
             * Função para ir para o cadastro
             */
            indicador(){
                window.location.href = '/indicadores'
            },
            /**
             * Função para ir para o cadastro
             */
            pesquisa(){
                window.location.href = '/pesquisa'
            },
            /**
             * Função para ir para a complemento
             */
            complemento(){
                window.location.href = '/complemento'
            },
            /**
             * Função para ir para os usuarios
             */
            usuariosPagina(){
                window.location.href = '/usuarios'
            },
            /**
             * Função para ir para as estatisticas
             */
            estatisticas(){
                window.location.href = '/estatisticas'
            },
            /**
             * Função para ir para as enderecos
             */
            enderecos(){
                window.location.href = '/enderecos'
            },
            /**
             * Função para ir para os documentos
             */
            documentos(){
                window.location.href = '/documentos'
            },
            /**
             * Função para sair da aplicação
             */
            sair(){
                sessionStorage.removeItem('access_token')

                window.location.href = '/'
            },
            /**
             * Função para cancular a expiracao
             */
            calcularExpiracao() {
                const dataAtualUnix = Math.floor(Date.now() / 1000)
                const diferencaSegundos = this.autenticacao.exp - dataAtualUnix

                if(diferencaSegundos <= 10){
                    this.contagemRegressivaClass = 'danger'
                }else{
                    this.contagemRegressivaClass = 'default'
                }

                if (diferencaSegundos <= 0) {
                    clearInterval(this.expiracaoInterval)
                    this.contagemRegressiva = "Sessão expirada"
                } else {
                    const horas = Math.floor((diferencaSegundos % 86400) / 3600)
                    const minutos = Math.floor((diferencaSegundos % 3600) / 60)
                    const segundos = diferencaSegundos % 60

                    this.contagemRegressiva = `A sessão expira em ${horas < 10 ? '0'+horas : horas}:${minutos < 10 ? '0'+minutos : minutos}:${segundos < 10 ? '0'+segundos : segundos}`
                }
            },
            /**
             * Função para atualizar o token
             */
             async refreshToken(){
                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")

                /**
                * Define os opções da requisição
                * 
                * @var {object} oOpcoes
                */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow',
                    body: JSON.stringify({ token: this.autenticacao.refresh_token })
                }

                /**
                * Realiza a requisição para buscar os detalhes do usuario
                * 
                * @var {object} oLogin
                */
                const oLogin = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/refreshToken`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!oLogin){
                    toastAlert('Sua sessão expirou, redirecionando para a autenticação...')

                    setTimeout(() => {
                        /**
                        * Desloga da aplicação
                        */
                        this.sair()
                    }, 2000)

                    /**
                    * Define os dados nas variaveis
                    */
                    this.autenticacao.autenticado = false

                    return false
                }    

                if(oLogin?.AccessToken){
                    sessionStorage.setItem('access_token', oLogin.AccessToken)
                }

                /**
                * Realizar a autenticação
                */
                await autenticar(this)

                /**
                * Realiza a contagem da expiração
                */
                this.calcularExpiracao()
                this.expiracaoInterval = setInterval(this.calcularExpiracao, 1000)
            },
            async exportar(oDados){
                try {
                    this.carregando = true
              
                    /**
                    * Define as variaveis para baixar o conteudo da exportação
                    * 
                    * @var {string} sBlob
                    * @var {string} sUrl
                    * @var {object} oLink
                    */
                    const sBlob = await this.baixarExportacao(oDados.hash, oDados.tamanhoArquivo)
                    const sUrl = window.URL.createObjectURL(sBlob)
                    const oLink = document.createElement('a')
                  
                    this.progress = 100

                    oLink.href = sUrl
                    oLink.download = `${oDados.tipo ?? 'onr'}_${oDados.hash}.${oDados.tipoArquivo.split('/')[1]}`
                    document.body.appendChild(oLink)
                    oLink.click()
                    document.body.removeChild(oLink)

                    setTimeout(() => {
                        this.progress = 0
                        this.carregando = false
                    }, 1000)                    
                } catch (error) {
                    console.error('Erro ao baixar o arquivo:', error)
                }
            },
            /**
             * Função para baixar a parte do arquivo
             */
            async baixarParteArquivo(sHash, partIndex) {
                try {
                    /**
                     * Realizar a autenticação
                     */
                    await autenticar(this)

                    /**
                    * Baixa uma parte do arquivo
                    * 
                    * @var {object} oResponse
                    */
                    const oResponse = await fetch(
                        `${process.env.VUE_APP_ENDPOINT_API}/v1/exportacao/download/${sHash}`,
                        {
                            method: 'GET',
                            headers: {
                                "Cache-Control": "no-cache",
                                'Range': `bytes=${partIndex * 1024 * 1024}-${(partIndex + 1) * 1024 * 1024 - 1}`,
                                'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                            }
                        }
                    )

                    if (!oResponse.ok) {
                        throw new Error(`Erro ao baixar parte do arquivo: ${oResponse.statusText}`)
                    }

                    /**
                     * Pega a resposta JSON e decodifica o conteúdo Base64
                     * 
                     * @var {object} oRetorno
                     */
                    const oRetorno = await oResponse.json()

                    /**
                     * Decofica o conteudo
                     * 
                     * @var {string} sConteudo
                     * @var {array} aBytes
                     */
                    const sConteudo = window.atob(oRetorno.conteudo)
                    const aBytes = new Array(sConteudo.length)

                    for (let i = 0; i < sConteudo.length; i++) {
                        aBytes[i] = sConteudo.charCodeAt(i)
                    }

                    return new Blob([new Uint8Array(aBytes)], { type: 'application/octet-stream' })
                } catch (error) {
                    console.error('Erro ao baixar parte do arquivo:', error)
                    throw error
                }
            },
            /**
             * Função para baixar o arquivo da exportação por partes
             */
            async baixarExportacaoPorPartes(sHash, iTamanhoArquivo) {
                try {
                    /**
                    * Define o array das partes do arquivo e o tamanho do arquivo
                    * 
                    * @var {array} aPartesArquivo
                    * @var {int} iTotal
                    */
                    const aPartesArquivo = []
                    const iTotal = Math.ceil(iTamanhoArquivo / (1024*1024))
                    
                    for await (let i of Array(iTotal).keys()) { 
                        this.progress = i == 0 ? 1 : Math.round((i / iTotal) * 100)

                        /**
                        * Baixa a parte do arquivo
                        * 
                        * @var {string} sParteArquivo
                        */
                        const sParteArquivo = await this.baixarParteArquivo(sHash, i)
                        aPartesArquivo.push(sParteArquivo)
                    }

                    return aPartesArquivo
                } catch (error) {
                    console.error('Erro ao baixar todas as partes do arquivo:', error)
                    throw error
                }
            },
            /**
             * Função para baixar o arquivo da exportação
             */
            async baixarExportacao(sHash, iTamanhoArquivo) {
                try {
                    /**
                    * Baixar o arquivo da exportação
                    * 
                    * @var {array} aPartesArquivo
                    * @var {string} sBlob
                    */
                    const aPartesArquivo = await this.baixarExportacaoPorPartes(sHash, iTamanhoArquivo)
                    const sBlob = new Blob(aPartesArquivo)

                    return sBlob
                } catch (error) {
                    console.error('Erro ao baixar as partes do arquivo:', error)
                    throw error
                }
            },
            /**
             * Função para exportar os registros
             */
            async exportarOnr(){
                /**
                 * Define os parametros para a requisição
                 * 
                 * @var {string} sParametros
                 */
                const sParametros = qs.stringify({
                    dataInicialCadastro: this.exportacao.dataInicialCadastroExportacao,
                    dataFinalCadastro: this.exportacao.dataFinalCadastroExportacao,
                    dataInicial: this.exportacao.dataInicial,
                    dataFinal: this.exportacao.dataFinal,
                    exportacaoONR: [0,2],
                    exportacao: 1,
                    rascunho: 0,
                    usuario: this.autenticacao.usuario
                })

                /**
                * Define os headers da requisição
                * 
                * @var {object} oHeaders
                */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                
                this.carregando = true

                /**
                 * Realizar a autenticação
                 */
                await autenticar(this)

                await axios.request({
                    method: 'post',
                    url: `${process.env.VUE_APP_ENDPOINT_API}/v1/exportacao/onr?${sParametros}`,
                    headers: { 
                        'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    toastAlert('Exportação solicitada com sucesso', 'success')
                
                    this.carregando = false
                    this.exportacao.dataInicial = ''
                    this.exportacao.dataFinal = ''
                    this.exportacao.dataInicialCadastroExportacao = ''
                    this.exportacao.dataFinalCadastroExportacao = ''

                    this.pesquisar(true, true)
                })
                .catch((error) => {
                    if(error?.response?.status && error.response.status == 422){
                        toastAlert('Não foi possível exportar os dados, pois não há registros para o período informado')
                    }else{
                        console.log(error)
                        toastAlert(`Erro ao exportar os dados`)
                    }
                
                    this.carregando = false
                })
            },
            /**
             * Função para visualizar os erros da exportação
             */
            visualizarErros(aErros){
                this.dadosErros = aErros         
            }
        }
    }
</script>

<style>
    html{
        overflow: hidden;
        background-color: #E5DDD5;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .div-principal{
        height: 100vh;
        overflow: hidden;
    }

    .barra-superior{
        margin: 0;
        height: 50px;
        background: #EDEDED;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
        display: flex;
        justify-content: space-between;
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .indicador{
        padding: 0;
        background:#E5DDD5;
        position: relative;
    }

    .indicadores{
        height: 83vh;
        margin: 10px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
        overflow-x: hidden;
    }

    .lista-indicadores{
        display: none;
        justify-content: flex-end;
        flex-direction: column;
    }

    .formulario{
        margin: 0 auto;
        position: relative;
        width: 96vw;
        background: #fff;
        padding: 1rem 1rem;
        border-radius: 1rem;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 8rem;
    }

    .indicador{
        height: 98%!important;
        right: -2px;
        top: -11px;
        padding-top: 12px;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        border-top: 1px solid #ddd;
        width: 96vw;
        padding: 1rem 2rem 0rem 2rem;
        margin-left: -1rem;
    }

    .selectize-input{
        padding: 10px 8px!important;
    }

    .formulario .columns{
        flex-wrap: wrap;
        padding: 0.5rem;
        margin-top: -0.5rem!important;
        align-items: flex-end;
        padding-bottom: 15px;
    }

    .formulario .column{
        padding: 0 0.5rem!important;
        margin-top: 0rem!important;
    }

    .formulario .column.is-one-tenth,.column.is-one-tenth-tablet {
        flex: none;
        width: 10%
    }

    .formulario .label {
        margin-top: 0.5rem;
        margin-bottom: 0.2rem!important;
        font-size: 12px;
    }

    .tabs{
        align-items: flex-end;
        margin-bottom: 0px;
        margin-left: -1rem;
        width: 96vw;
    }
    
    .tabs ul{
        padding-left: 2rem;
    }

    .formulario .numero{
        position: absolute;
        right: 25px;
        top: 1rem;
        font-size: 18px;
    }

    .btn-rotate > .icon{
        transform: rotate(270deg);
    }
    
    .column-date{
        display: flex; 
        gap: 1rem;
    }

    .column-date > span{
        margin-top: 2.25rem;
    }

    .column-date > .control{
        width: 100%;
    }

    .selectize-control.multi .selectize-input.has-items{
        padding: 6px 8px!important;
    }

    .column-table{
        border-bottom: 1px solid rgb(200, 200, 200);
        padding-top: 0!important;
        padding-bottom: 0!important;
    }

    .btn-limpar{
        margin-top: 1rem;
    }

    .btn-pesquisar{
        margin-top: 1rem;
        float: right;
    }

    .pagination{
        gap: 1rem;
    }

    .pagination > b{
        margin-left: -1.8rem;
        width: 6rem;
    }

    .pagination, .pagination-list{
        display: inline-flex;
    }

    .pagination-previous{
        order: 0;
    }

    .pagination-previous.is-disabled, .pagination-next.is-disabled{
        opacity: .7;
        pointer-events: none;
    }

    .column-nao-encontrado{
        justify-content: center;
        align-items: center;
    }

    .column-nao-encontrado > div{
        margin: 1rem 1rem 1.5rem 1rem;
    }

    .pagination-link.is-current{
        background-color: #000;
        border-color: #dbdbdb;
    }
</style>
